<template>
  <div class="page">
    <div class="container">
      <div class="page-title mt-4">
        <h1 style="font-size: 2rem;">お申込み前のご確認</h1>
        <p>お申込みいただくにあたり、以下をお読みください。</p>
      </div>
      <div class="mt-5">
        <div class="sub-title">
          <h1>システム利用規約</h1>
          <div class="control">
            <a href="/policy/BIZHIWAY会員規約.pdf" target="_blank">
              <font-awesome-icon class="va-m" icon="download"></font-awesome-icon>
              システム利用規約をダウンロード
            </a>
          </div>
        </div>
        <div class="policy-text mt-3">
          <pre>
この利用規約（以下，「本規約」といいます。）は，＿＿＿＿＿（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。

第1条（適用）
本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
第2条（利用登録）
本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。
当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
利用登録の申請に際して虚偽の事項を届け出た場合
本規約に違反したことがある者からの申請である場合
その他，当社が利用登録を相当でないと判断した場合
第3条（ユーザーIDおよびパスワードの管理）
ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。
第4条（利用料金および支払方法）
ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。
ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。
第5条（禁止事項）
ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。

法令または公序良俗に違反する行為
犯罪行為に関連する行為
本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
本サービスによって得られた情報を商業的に利用する行為
当社のサービスの運営を妨害するおそれのある行為
不正アクセスをし，またはこれを試みる行為
他のユーザーに関する個人情報等を収集または蓄積する行為
不正な目的を持って本サービスを利用する行為
本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
他のユーザーに成りすます行為
当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
面識のない異性との出会いを目的とした行為
当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
その他，当社が不適切と判断する行為
第6条（本サービスの提供の停止等）
当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
コンピュータまたは通信回線等が事故により停止した場合
その他，当社が本サービスの提供が困難と判断した場合
当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
          </pre>
        </div>
      </div>
      <div class="mt-5">
        <div class="sub-title">
          <h1>反社会的勢力排除に関する誓約書</h1>
          <div class="control">
            <a href="/policy/反社会的勢力排除に関する誓約書.pdf" target="_blank">
              <font-awesome-icon class="va-m" icon="download"></font-awesome-icon>
              反社誓約書をダウンロード
            </a>
          </div>
        </div>
        <div class="policy-text mt-3">
          <pre>
私は、下記の事項について誓約をいたします。

１．当機関は、自ら（主要な出資者、役員、及びそれに準ずる者を含む）が暴力団、暴力団員・準構成員、暴力団関係企業、特殊知能暴力集団の関係者その他公益に反する行為をなす者(以下「暴力団員等」という)でないこと、並びに、過去５年間もそうでなかったこと、及び次の各号のいずれにも該当しないことを表明し、かつ暴力団員等を利用しないことを誓約する。

①暴力団員等が経営を支配していると認められる関係を有すること
②暴力団員等が経営に実質的に関与していると認められる関係を有すること
③自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
④暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
⑤役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること

２．当機関は、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを誓約する。
①暴力的な要求行為
②法的な責任を超えた不当な要求行為
③取引に関して、脅迫的な言動をし、または暴力を用いる行為
④風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の
業務を妨害する行為
⑤その他①から④に準ずる行為
          </pre>
        </div>
      </div>
      <div class="mt-5">
        <div class="sub-title">
          <h1>BIZHIWAY会員規約</h1>
          <div class="control">
            <a href="/policy/BIZHIWAY会員規約.pdf" target="_blank">
              <font-awesome-icon class="va-m" icon="download"></font-awesome-icon>
              BIZHIWAY会員規約をダウンロード
            </a>
          </div>
        </div>
        <div class="policy-text mt-3">
          <pre>
この利用規約（以下，「本規約」といいます。）は，＿＿＿＿＿（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。

第1条（適用）
本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
第2条（利用登録）
本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。
当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
利用登録の申請に際して虚偽の事項を届け出た場合
本規約に違反したことがある者からの申請である場合
その他，当社が利用登録を相当でないと判断した場合
第3条（ユーザーIDおよびパスワードの管理）
ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。
第4条（利用料金および支払方法）
ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。
ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。
第5条（禁止事項）
ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。

法令または公序良俗に違反する行為
犯罪行為に関連する行為
本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
本サービスによって得られた情報を商業的に利用する行為
当社のサービスの運営を妨害するおそれのある行為
不正アクセスをし，またはこれを試みる行為
他のユーザーに関する個人情報等を収集または蓄積する行為
不正な目的を持って本サービスを利用する行為
本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
他のユーザーに成りすます行為
当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
面識のない異性との出会いを目的とした行為
当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
その他，当社が不適切と判断する行為
第6条（本サービスの提供の停止等）
当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
コンピュータまたは通信回線等が事故により停止した場合
その他，当社が本サービスの提供が困難と判断した場合
当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
          </pre>
        </div>
      </div>
      <div class="controls text-center">
        <b-form-checkbox id="agreed" v-model="agreed" name="agreed" class="d-inline-block" value=true unchecked-value=false >
          上記の内容を理解し、同意しました。
        </b-form-checkbox>
        <br>
        <j-button class="mt-4" variant="primary" size="lg" :disabled="!agreed" v-on:click="onAgreeClick">
          会員登録へ
        </j-button>
        <br>
        <j-button class="mt-4" variant="light">
          <font-awesome-icon class="va-m" icon="arrow-left"></font-awesome-icon>
          ホームへ戻る
        </j-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .policy-text pre{
    height: 275px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
    font-family: 'MS Mincho';
    font-size: 1rem;
    border: 1px solid $biz-border;
  }
</style>
<script>
  
  export default {
    components: {  },
    data: () => ({
      agreed: false
    }),
    methods: {
      onAgreeClick() {
        if(this.agreed){
          this.$router.push({name: 'InitiateMailAddress', query: {open: true} });
        }
      }
    }
  }
</script>